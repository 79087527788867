<template>
    <div class="content-wrapper">
        <bo-page-title />
        <div class="content pt-0">
            <b-card no-body class="mb-3">
                <b-card-header>
                    <h6 class="card-title font-weight-semibold">Data Kunjungan</h6>
                </b-card-header>
                <div class="card-body p-2">
                    <div class="row">
                    <div class="col-lg-10" id="formOptPenunjang">
                        <div class="row gx-1">
                        <div class="col-md-4">
                            <v-select placeholder="Pilih Jenis Faskes" v-model="jnsLPK" :options="Config.mr.jenisPelayanan" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group mb-3">
                                <datepicker input-class="form-control transparent"
                                placeholder="Pilih Tanggal" class="my-datepicker"
                                calendar-class="my-datepicker_calendar" v-model="inpLPK">
                                </datepicker>
                                <div class="input-group-append calendar-group">
                                <span class="input-group-text" id="basic-addon2"><i
                                    class="icon-calendar"></i></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <button @click="searchLPK" type="button" class="btn btn-labeled btn-labeled-left bg-blue-400 text-orange-800 mr-1"><b><i
                                class="icon-search4"></i></b>Cari</button>
                            <button type="button" class="btn apha-blue border-blue text-blue-800" @click="resetLPK">Reset</button>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div class="row pt-2" v-if="shwLPK">
                        <div class="col-lg-12 table-responsive">
                            <table class="table table-striped">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>DPJP</th>
                                    <th>diagnosa</th>
                                    <th>Jenis Pelayanan</th>
                                    <th>no Sep</th>
                                    <th>caraKeluar</th>
                                    <th>kelasRawat</th>
                                    <th>kondisiPulang</th>
                                    <th>ruangRawat</th>
                                    <th>spesialistik</th>
                                    <th>jenis kelamin</th>
                                    <th>nama</th>
                                    <th>noKartu </th>
                                    <th>noMR</th>
                                    <th>tglLahir</th>
                                    <th>poli</th>
                                    <th>procedure</th>
                                    <th>rencanaTL</th>
                                    <th>tglKeluar</th>
                                    <th>tglMasuk</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(v,k) in dataLPK.lpk.list" :key="v.kode">
                                        <td>{{k+1}}</td>
                                        <td>
                                          <p>Kode : {{v.DPJP.dokter.kode||"-"}}</p>
                                          <p>Nama : {{v.DPJP.dokter.nama||"-"}}</p>
                                        </td>
                                        <td>
                                          <ul v-for="(v1,k1) in v.diagnosa.list" :key="k1">
                                            <li>{{v1.list.kode||"-"}} - {{v1.list.nama||"-"}}</li>
                                          </ul>
                                        </td>
                                        <td>
                                          <p>{{v.jnsPelayanan||"-"}}</p>
                                        </td>
                                        <td>
                                          <p>{{v.noSep||"-"}}</p>
                                        </td>
                                        
                                        <td>
                                          <p>Kode : {{v.perawatan.caraKeluar.kode||"-"}}</p>
                                          <p>Nama : {{v.perawatan.caraKeluar.nama||"-"}}</p>
                                        </td>
                                        
                                        <td>
                                          <p>Kode : {{v.perawatan.kelasRawat.kode||"-"}}</p>
                                          <p>Nama : {{v.perawatan.kelasRawat.nama||"-"}}</p>
                                        </td>
                                        
                                        <td>
                                          <p>Kode : {{v.perawatan.kondisiPulang.kode||"-"}}</p>
                                          <p>Nama : {{v.perawatan.kondisiPulang.nama||"-"}}</p>
                                        </td>
                                        
                                        <td>
                                          <p>Kode : {{v.perawatan.ruangRawat.kode||"-"}}</p>
                                          <p>Nama : {{v.perawatan.ruangRawat.nama||"-"}}</p>
                                        </td>
                                        
                                        <td>
                                          <p>Kode : {{v.perawatan.spesialistik.kode||"-"}}</p>
                                          <p>Nama : {{v.perawatan.spesialistik.nama||"-"}}</p>
                                        </td>
                                        
                                        <td>
                                          <p>{{v.peserta.kelamin||"-"}}</p>
                                        </td>
                                        
                                        <td>
                                          <p>{{v.peserta.nama||"-"}}</p>
                                        </td>
                                        
                                        <td>
                                          <p>{{v.peserta.noKartu||"-"}}</p>
                                        </td>
                                        
                                        <td>
                                          <p>{{v.peserta.noMR||"-"}}</p>
                                        </td>

                                        <td>
                                          <p>{{v.peserta.tglLahir | moment("DD MMMM YYYY")}}</p>
                                        </td>
                                        
                                        <td>
                                          <p>eksekutif : {{v.poli.eksekutif ||"-" }}</p>
                                          <p>poli : {{v.poli.poli.kode||"-"}}</p>
                                        </td>
                                        
                                        <td>
                                          <ul v-for="(v2,k2) in v.procedure.list" :key="k2">
                                            <li>{{v2.list.kode||"-"}} - {{v2.list.nama||"-"}}</li>
                                          </ul>
                                        </td>
                                        <td>
                                          <p>{{v.rencanaTL||"-"}}</p>
                                        </td>
                                        <td>
                                          <p>{{v.tglKeluar | moment("DD MMMM YYYY") }}</p>
                                        </td>
                                        <td>
                                          <p>{{v.tglMasuk | moment("DD MMMM YYYY") }}</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </b-card>
        </div>
    </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
const _ = global._
import Gen from '@/libs/Gen.js'
import Datepicker from 'vuejs-datepicker'
const moment = require('moment')


export default {
  extends: GlobalVue,
  components: {
    Datepicker
  },
  data() {
    return {
        inpLPK: null,
        jnsLPK: null,
        shwLPK: false,  
        dataLPK: {},
    }
  },
  methods: {
    searchLPK(){
        if(this.inpLPK &&  this.jnsLPK){
            this.loadingOverlay = true
            let data = {
                type : "search-lpk",
                dates : this.inpLPK,
                jenis : this.jnsLPK
            }
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data: data}, 
                "POST"
            ).then(res=>{
                let resp = res.data
                if(resp.status == "fail"){
                    this.loadingOverlay = false
                    this.shwLPK = false
                    return this.$swal({
                        icon: 'error',
                        text: 'Data LPK Tidak Ditemukan'
                    })
                }
                this.loadingOverlay = false
                this.dataLPK = resp.response
                this.shwLPK = true
            }).catch(err=>{
                this.loadingOverlay = false
                return this.$swal({
                    icon: 'error',
                    text: 'Data LPK Tidak Ditemukan'
                })
            })
        }
    },
    resetLPK(){
        this.inpLPK = null
        this.shwLPK = false
        this.dataLPK = {}
    },
  }   
}

</script>

<style lang="scss" style="scoped">
    .vue-daterange-picker{
        width: 220px;
    }
</style>